import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { callAPI } from "../redux-middleware/call-api";
import { initApi } from "utils/api";
import messageMiddleware from "../redux-middleware/message.js";
import broadcastChannelMiddleware from "../redux-middleware/broadcast-channel";
// import { createLogger } from "redux-logger";
import compact from "lodash/compact";

const extensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export default function configureStore(initialState) {
  // const logger = createLogger({
  //   collapsed: true,
  //   duration: true
  //   // diff: true,
  // });
  const sagaMiddleware = createSagaMiddleware();
  const enhancers = compact([
    applyMiddleware(
      sagaMiddleware,
      messageMiddleware,
      thunk,
      callAPI,
      broadcastChannelMiddleware()
      // logger
    )
  ]);
  const composeEnhancers = extensionCompose || compose;
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  initApi(store);
  sagaMiddleware.run(rootSaga);

  return store;
}
